import React from 'react';
// type
import { ICandidateRequestAvailability, IInterviewSpec, IInterviewStage } from '../../types/calendar';
import { useSelector } from 'react-redux';
// styled
import { DefaultTheme } from 'styled-components';
import { ItemLabel3, RemoveIconDiv, ScheduleItemWrapper, ScheduleWrapper, SlotsWrapper, SubLabel } from './CandidateInterview.styled';
// maui components
import { Button, DatePicker, Icon } from 'maui';
// utils
import moment from 'moment';
import { appendPlural } from 'findem-helpers';

interface IScheduleByCandidatePrefProps {
  goToNext: () => void;
  requestAvailabilityObj: ICandidateRequestAvailability | undefined;
  updatePreferredOpenSlots?: (slot: [start_ts: number, end_ts: number], operation: 'add' | 'remove') => void;
  specDetails?: IInterviewSpec;
  preferredOpenSlots?: [start_ts: number, end_ts: number][];
}

const {RangePicker} = DatePicker;

const ScheduleByCandidatePref: React.FC<IScheduleByCandidatePrefProps> = ({ 
  goToNext, requestAvailabilityObj, updatePreferredOpenSlots, specDetails, preferredOpenSlots
}) => {
  const themeC: DefaultTheme = useSelector((state: any) => state.appState.theme);
  const [value, setValue] = React.useState<[moment.Moment, moment.Moment] | undefined>(undefined);

  // memo
  const stage: IInterviewStage | undefined = React.useMemo(() => {
    if(specDetails && specDetails.stages && specDetails.stages.length > 0 && requestAvailabilityObj){
      const _reqForStage = requestAvailabilityObj.stage_idx;
      const stage: IInterviewStage | undefined = specDetails.stages.find((stage) => stage._id === _reqForStage);
      return stage;
    }
    return undefined;
  }, [specDetails, requestAvailabilityObj]);

  const interviewRoundsNames: string | undefined = React.useMemo(() => {
    if(stage){
      // join last round with AND rest by comma
      const rounds = stage.interview_round.map((round) => round.name);
      const lastRound = rounds.pop();
      return rounds.join(', ') + (lastRound ? ` and ${lastRound}` : '');
    }
    return undefined;
  }, [stage]);

  const sortedPreferredOpenSlots = React.useMemo(() => {
    return preferredOpenSlots ? preferredOpenSlots.sort((a, b) => a[0] - b[0]) : [];
  }, [preferredOpenSlots]);

  React.useEffect(() => {
    if(preferredOpenSlots && preferredOpenSlots.length > 0){
      setValue(undefined);
    }
  }, [preferredOpenSlots]);

  const disabledDateTime = () => ({
    // show mins in 15 mins interval
    disabledMinutes: (h: number) => {
      const mins = [];
      for(let i = 0; i < 60; i++){
        if(i % 15 !== 0){
          mins.push(i);
        }
      }
      return mins;
    },
  });


  return (
    <>
      {!stage && <div>Interview Round Details Not Found</div>}
      {stage && <>
        <ScheduleWrapper>
          <ScheduleItemWrapper
            style={{
              margin: 'auto',
              fontWeight: '500',
              textAlign: 'center',
            }}
          >
              Choose your preferred open slots for interview round{appendPlural(stage.interview_round.length)} {interviewRoundsNames}.
          </ScheduleItemWrapper>
          <ScheduleItemWrapper showBackground={true}>
            <SubLabel>Preferred Open Slots</SubLabel>
            {sortedPreferredOpenSlots.length > 0 && <SlotsWrapper>
              {sortedPreferredOpenSlots.map((slot, idx) => {
                return (
                  <ItemLabel3 key={idx}>
                    {moment.unix(slot[0]).format('DD MMM (hh:mm a)')} - {moment.unix(slot[1]).format('DD MMM (hh:mm a)')}
                    <RemoveIconDiv onClick={() => updatePreferredOpenSlots && updatePreferredOpenSlots(slot, 'remove')}>
                      <Icon icon="delete" />
                    </RemoveIconDiv>
                  </ItemLabel3>
                );
              })}
            </SlotsWrapper>}
            <RangePicker
              showTime={{ 
                format: 'hh:mm a',
                hideDisabledOptions: true,
              }}
              format="YYYY-MM-DD hh:mm a"
              value={value}
              disabledTime={disabledDateTime}
              disabledDate={(current) => moment().add(0, 'days')  >= current ||
                moment().add(1, 'month')  <= current}
              onChange={(dates, dateStrings) => {
                if(dates && dates.length > 0 && dates[0] && dates[1]){
                  updatePreferredOpenSlots && updatePreferredOpenSlots([dates[0].unix(), dates[1].unix()], 'add');
                  setValue(dates as [moment.Moment, moment.Moment]);
                }
              }}
            />
            <Button
              type="primary"
              disabled={sortedPreferredOpenSlots.length === 0}
              style={{width: '100%', marginTop: 16}}
              onClick={goToNext}
            >
              Next
            </Button>
          </ScheduleItemWrapper>
        </ScheduleWrapper>
      </>}
    </>
  );
};
export default ScheduleByCandidatePref;