import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { ICandidateInterviewSlot, IInterviewBranding, IInterviewCandidateSlots, ISlot } from "../../types/calendar";
import { slotsByDayHelper } from "../CandidateInterview/helper";
import { useGetCandidateInterviewOneOffCQuery, useGetInterviewPageLogoQuery } from "../../services/interviews";
import { STEP } from "../CandidateInterview/constant";
import { CandidateInterviewWrapper, ContentWrapper, HI, HiInfo, InfoWrapper } from "../CandidateInterview/CandidateInterview.styled";
import { Skeleton } from "maui";
import moment from "moment";
import InterviewInfo from "../CandidateInterview/InterviewInfo";
import Schedule from "../CandidateInterview/Schedule";
import ConfirmationForm from "../CandidateInterview/ConfirmationForm";
import { useUrlParams } from "findem-helpers";
import { useGetCandidatePubInterviewOneOffCQuery, useGetPubInterviewPageLogoByTokenQuery, useGetPubInterviewerSlugDetailsQuery } from "../../services/pub-interviews";
import { useDispatch, useSelector } from "react-redux";
import { appStateActions } from "../../reducers/appState";
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { getRecaptchaKey } from '../../constants/captcha';
import { set } from "lodash";

// Components

const PersonalBooking = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const dispatch = useDispatch();
  const recaptcha = useRef<ReCaptchaInstance>();

  // context state
  const branding: IInterviewBranding | undefined = useSelector((state: any) => state.appState.branding);

  // states
  const [interviewSlots, setInterviewSlots] = React.useState<ICandidateInterviewSlot[][] | undefined>(undefined);
  const [slotsByDay, setSlotsByDay] = React.useState<IInterviewCandidateSlots | undefined>(undefined);
  const [step, setStep] = React.useState<STEP>(STEP.INTRO);
  const [slotObj, setSlotObj] = React.useState<ISlot | undefined>(undefined); // [start_ts, end_ts]
  const [slotObjs, setSlotObjs] = React.useState<ISlot[]>([]); // [start_ts, end_ts]
  const [captchaTokens, setCaptchaTokens] = React.useState<{
    [key: string]: string | undefined;
  }>({ interview: undefined, logo: undefined, candidate: undefined});

  // apis
  const {data: interviewer, isLoading: isLoadingInterviewer} = useGetPubInterviewerSlugDetailsQuery({ slug: id || '', token: captchaTokens.interview || '' }, {
    skip: !id || id.length < 1 || !captchaTokens.interview,
  });

   // memo
   const _accountId: string | undefined = React.useMemo(() => {
    if(!interviewer) return undefined;
    return interviewer.account_id;
  }, [interviewer]);

  // apis
  const { data: savedLogo, isLoading: isLogoLoading } = useGetPubInterviewPageLogoByTokenQuery({accountId: _accountId, joid: undefined, token: captchaTokens.logo || ''}, {
    skip: !_accountId  || !captchaTokens.logo
  });
  const {data: candidateInterviewSlots, isLoading, refetch: refetchSlots} = useGetCandidatePubInterviewOneOffCQuery({
    slug: id || '',
    token: captchaTokens.candidate || '',
  },{
    skip: !id || id.length < 1 || !captchaTokens.candidate,
  });

  // callbacks
  const step2 = React.useCallback(() => {
    setStep(STEP.CALENDAR);
  },[]);

  const step3 = React.useCallback(() => {
    setStep(STEP.FORM);
  },[]);

  const updateSlot = React.useCallback((slot: ISlot) => {
    // check if slot is there
    const _slot: ISlot | undefined = slotObjs.find((s: ISlot) => s.slot[0].start === slot.slot[0].start);
    if(_slot){
      const _slotObjs = slotObjs.filter((s: ISlot) => s.slot[0].start !== slot.slot[0].start);
      setSlotObjs([..._slotObjs, slot]);
    } else {
      setSlotObjs([...slotObjs, slot]);
    }
  },[slotObjs]);

  const goToConfirmation = React.useCallback(() => {
    step3();
  },[]);

  const onSubmitCB = React.useCallback(() => {
    setStep(STEP.BOOKED);
  }, []);

  // effects
  React.useEffect(() => {
    if (candidateInterviewSlots) {
      setInterviewSlots(candidateInterviewSlots);
    }
  }, [candidateInterviewSlots]);

  React.useEffect(() => {
    if(interviewSlots){
      setSlotsByDay(slotsByDayHelper(interviewSlots));
    }
  }, [interviewSlots]);

  React.useEffect(() => {
    const preload = async () => {
      recaptcha.current = await load(getRecaptchaKey());
      const token_1 = await recaptcha?.current?.execute();
      const token_2 = await recaptcha?.current?.execute();
      const token_3 = await recaptcha?.current?.execute();
      const token_4 = await recaptcha?.current?.execute();
      setCaptchaTokens({
        interview: token_1,
        logo: token_2,
        candidate: token_3,
      });
      dispatch(appStateActions.setCaptchaToken(token_4));
    }
    preload();
  }, [dispatch]);

   // effects
   React.useEffect(() => {
    if (interviewer) {
      dispatch(appStateActions.setAccountId(interviewer.account_id));
    }
  }, [_accountId, dispatch, interviewer]);

  return <CandidateInterviewWrapper>
    <ContentWrapper>
      {!isLoading && step === STEP.INTRO && <InterviewInfo logoUrl={savedLogo} goToSchedule={step2} />}
      {!isLoading && step === STEP.CALENDAR && <Schedule 
          slotsByDay={slotsByDay}
          selectedSlot={slotObj}
          selectedSlots={slotObjs}
          updateSlot={updateSlot}
          goToNext={goToConfirmation}
          requestAvailabilityObj={undefined}
        />
      }
      {slotObjs && id && !isLoading && step === STEP.FORM && _accountId && <ConfirmationForm 
          slotObjs={slotObjs}
          goBack={step2}
          interview_candidate_id={id}
          onSubmitCB={onSubmitCB}
          refetchSlots={refetchSlots}
          logoUrl={savedLogo}
          accountId={_accountId}
      />}
      {isLoading && <Skeleton title={false} loading={isLoading} paragraph={{rows: 4}}/>}
      {step === STEP.BOOKED && slotObj && <InfoWrapper>
        <HI>Interview Booked</HI>
        <HiInfo style={{marginTop: 8}}>
          {(!branding || !branding.thank_you_msg) && <>Your interview has been booked for {moment(slotObj.slot[0].start).format('dddd, MMMM Do')} at {moment(slotObj.slot[0].start).format('h:mm A')}.</>}
          {branding && branding.thank_you_msg && <> {branding.thank_you_msg.replace('{{date}}', `${moment(slotObj.slot[0].start).format('dddd, MMMM Do')} at ${moment(slotObj.slot[0].start).format('h:mm A')}`)}</>}
        </HiInfo>
      </InfoWrapper>}
    </ContentWrapper>
  </CandidateInterviewWrapper>;
};

export default PersonalBooking;
