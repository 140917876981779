import { apiUrl, useUrlParams } from "findem-helpers";
import React from "react";
import { useParams } from "react-router-dom";
import { CandidateInterviewWrapper, ContentWrapper, DayTitle, HI, HiInfo, InfoWrapper, ItemLabel2, LogoWrapper, TimeSlotWrapper } from "./CandidateInterview.styled";
import InterviewInfo from "./InterviewInfo";
import { STEP } from "./constant";
import Schedule from "./Schedule";
import { Skeleton, theme } from "maui";
import { durationInMinutes, preferredOpenSlotsGroupByDay, slotsByDayHelper } from "./helper";
import ConfirmationForm from "./ConfirmationForm";
import { useGetProfilesQuery } from "../../services/interviews";
import moment from "moment";
import { ICandidateInterviewSlot, ICandidateRequestAvailability, IInterviewBranding, IInterviewCandidate, IInterviewCandidateSlots, IInterviewSpec, IPreferredSlot, ISlot, ISlotPrefs, ISpecAssignment } from "../../types/calendar";
import { useCandidatePubBookingsCV2Query, useGetPubCandidateInterviewCV2Query, useGetCandidateInterviewSpecQuery, useGetPubInterviewPageLogoQuery, useGetCandidatePubInterviewSlotsQuery, useGetCandidateReqAvailabilityQuery } from "../../services/pub-interviews";
import { useSelector } from "react-redux";
import logo from '../../assets/svgs/findem_logo.svg';

const CandidateInterview = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const {accountId, token} = useUrlParams();
  
  // states
  const [interviewSlots, setInterviewSlots] = React.useState<ICandidateInterviewSlot[][] | undefined>(undefined);
  const [slotsByDay, setSlotsByDay] = React.useState<IInterviewCandidateSlots | undefined>(undefined);
  const [specAssignment, setSpecAssignment] = React.useState<ISpecAssignment | undefined>(undefined);
  const [interviewCandidate, setInterviewCandidate] = React.useState<IInterviewCandidate | undefined>(undefined);
  const [specDetails, setSpecDetails] = React.useState<IInterviewSpec | undefined>(undefined);
  const [step, setStep] = React.useState<STEP>(STEP.INTRO);
  const [slotObj, setSlotObj] = React.useState<ISlot | undefined>(undefined); // [start_ts, end_ts]
  const [slotObjs, setSlotObjs] = React.useState<ISlot[]>([]); // [start_ts, end_ts]
  const [preferredOpenSlots, setPreferredOpenSlots] = React.useState<[start_ts: number, end_ts: number][]>([]); // [start_ts, end_ts]
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [overBlockError, setOverBlockError] = React.useState<boolean>(false);
  const [savedSlots, setSavedSlots] = React.useState<ISlot[]>([]); // [start_ts, end_ts]
  const [savedCandidateSlots, setSavedCandidateSlots] = React.useState<Record<string, [start_ts: number, end_ts: number][]>>({}); // [start_ts, end_ts]
  const [requestAvailabilityObj, setRequestAvailabilityObj] = React.useState<ICandidateRequestAvailability | undefined>(undefined);

  // context state
  const branding: IInterviewBranding | undefined = useSelector((state: any) => state.appState.branding);

  // apis
  const { data: savedLogo, 
    isLoading: isLogoLoading 
  } = useGetPubInterviewPageLogoQuery({
      accountId: accountId, 
      joid: undefined, token: token || '' 
    }, {skip: !accountId || !token || token.length < 1});
  // check for existing booking
  const {data: candidateEvent, isLoading: isCandidateEventsLoading} = useCandidatePubBookingsCV2Query({
    interview_candidate_id: id || '',
    token: token || '',
  },{
    skip: !id || !token || token.length < 1,
  });
  // check for request availability for type
  const { data: requestAvailabilityData, isLoading: isRequestAvailabilityLoading } = useGetCandidateReqAvailabilityQuery({
    interview_candidate_id: id || '',
    token: token || '',
    accountId: accountId || '',
  },{
    skip: !id || !accountId || !token || token.length < 1,
  });
  // check for slots
  const {data: candidateInterviewSlots, refetch: refetchSlots, isError: erroredOnLoadingSlots} = useGetPubCandidateInterviewCV2Query({
    interview_candidate_id: id || '',
    accountId: accountId || '',
    token: token || '',
  },{
    skip: !id || !accountId || !token || id.length < 1 || token.length < 1 || !requestAvailabilityObj || requestAvailabilityObj.open_slots_requested,
  });

  // need to move below api to public
  // api to get spec details
  const {
    data: interviewSpec,
  } = useGetCandidateInterviewSpecQuery(
    {
      candidate_refs: [id || ''],
      accountId: accountId || '',
      token: token || '',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !id || !accountId || !token || token.length < 1,
    }
  );

  // get slot preferences of candidate
  const {
    data: slotPreferences,
    isLoading: isSlotPreferencesLoading,
  } = useGetCandidatePubInterviewSlotsQuery({
    candidate_ref: id || '',
    token: token || '',
    spec_ref: specAssignment?.interview_spec_id || '',
    account_id: accountId || '',
  },{
    skip: !id || !accountId || !token || token.length < 1 || !specAssignment || !specAssignment.interview_spec_id,
  })
  // api to get profile details
  const { data: profiles } = useGetProfilesQuery(
    {
      profile_ids: [interviewCandidate ? interviewCandidate.prid : ''],
    },
    {
      skip: !interviewCandidate?.prid
    }
  );

  // Memo
  const candidateName: string = React.useMemo(() => {
    if (interviewCandidate && profiles) {
      return `${profiles[interviewCandidate.prid].candidate.full_name}`;
    }
    return '';
  }, [interviewCandidate, profiles]);

  const loading: boolean = React.useMemo(() => {
    return isLoading || isLogoLoading || !specDetails || isCandidateEventsLoading || isSlotPreferencesLoading;
  }, [isCandidateEventsLoading, isLoading, isLogoLoading, specDetails, isSlotPreferencesLoading]);

  const step2 = React.useCallback(() => {
    setStep(STEP.CALENDAR);
  },[]);

  const step3 = React.useCallback(() => {
    setStep(STEP.FORM);
  },[]);


  const updateSlot = React.useCallback((slot: ISlot) => {
    // check if slot is there
    const _slot: number = slotObjs.findIndex((s: ISlot) => s.slot.at(-1)?.start === slot.slot.at(-1)?.start && s.slot.at(-1)?.end === slot.slot.at(-1)?.end);
    if(_slot > -1){
      // remove slot at index
      const _slotObjs = slotObjs.filter((s: ISlot, index: number) => index !== _slot);
      setSlotObjs([..._slotObjs]);
    } else {
      if(slotObjs.length >= 3){
        setOverBlockError(true);
        return;
      }
      setSlotObjs([...slotObjs, slot]);
    }
    setOverBlockError(false);
  },[slotObjs]);

  const updatePreferredOpenSlots = React.useCallback((slot: [start_ts: number, end_ts: number], operation: 'add' | 'remove') => {
    const _slot: number = preferredOpenSlots.findIndex((s: [start_ts: number, end_ts: number]) => s[0] === slot[0] && s[1] === slot[1]);
    if(operation === 'add'){
      if(_slot === -1){
        setPreferredOpenSlots([...preferredOpenSlots, slot]);
      }
    } else {
      if(_slot > -1){
        const _preferredOpenSlots = preferredOpenSlots.filter((s: [start_ts: number, end_ts: number], index: number) => index !== _slot);
        setPreferredOpenSlots([..._preferredOpenSlots]);
      }
    }
  },[preferredOpenSlots]);

  const goToConfirmation = React.useCallback(() => {
    step3();
  },[]);

  const onSubmitCB = React.useCallback(() => {
    setSavedSlots(slotObjs);
    setSavedCandidateSlots(preferredOpenSlotsGroupByDay(preferredOpenSlots));
    setStep(STEP.PREFERRED);
  }, [slotObjs, preferredOpenSlots]);

  React.useEffect(() => {
    if (candidateInterviewSlots) {
      setInterviewSlots(candidateInterviewSlots);
    }
  }, [candidateInterviewSlots]);

  React.useEffect(() => {
    if (interviewSpec) {
      setSpecAssignment(interviewSpec);
    }
  }, [interviewSpec]);

  // API flow := candidate interview > spec assignment > spec details

  // Booking details.
  React.useEffect(() => {
    if(candidateEvent && candidateEvent.length > 0){
      const slot: ICandidateInterviewSlot = {
        start: moment(candidateEvent[0].start_time).unix()*1000,
        end: moment(candidateEvent[0].end_time).unix()*1000,
        interviewer_id: candidateEvent[0].interviewer_ref,
      }
      setSlotObj({
        slot: [slot],
        duration: durationInMinutes([slot]),
        label: moment(slot.start).format('h:mm A') + ' - ' + moment(slot.end).format('h:mm A'),
      });
      setStep(STEP.BOOKED);
    }
  },[candidateEvent]);

  // set request availability obj
  React.useEffect(() => {
    if(requestAvailabilityData && requestAvailabilityData.length > 0){
      setRequestAvailabilityObj(requestAvailabilityData.at(-1));
    }
  },[requestAvailabilityData]);

  // saved slots
  React.useEffect(() => {
    if(slotPreferences && slotPreferences.length > 0 && interviewCandidate){
      const _slotObjs: ISlot[] = [];
      const _interviewStageId = interviewCandidate.stage_idx;
      if(slotPreferences && slotPreferences.length > 0 && slotPreferences[0].preferred_open_slots && slotPreferences[0].preferred_open_slots.length > 0){
        setSavedCandidateSlots(preferredOpenSlotsGroupByDay(slotPreferences[0].preferred_open_slots));
        setStep(STEP.PREFERRED);
        return;
      }
      let _interviewPreferredSlots: IPreferredSlot[] | undefined = slotPreferences.find((slotPrefs: ISlotPrefs) => slotPrefs.stage_idx === _interviewStageId)?.preferred_slots;
      if(!_interviewPreferredSlots && slotPreferences.length > 0 ){
        _interviewPreferredSlots = slotPreferences[0].preferred_slots;
      }
      _interviewPreferredSlots && _interviewPreferredSlots.forEach((slot: IPreferredSlot) => {
       const _slot: ICandidateInterviewSlot[] = [];
        Object.keys(slot).forEach((key: string) => {
          _slot.push({
            start: slot[key].start_ts,
            end: slot[key].end_ts,
            interviewer_id: key,
          });
        });
        _slotObjs.push({
          slot: _slot,
          duration: durationInMinutes(_slot),
          label: moment(_slot[0].start).format('h:mm A') + ' - ' + moment(_slot.at(-1)?.end).format('h:mm A'),
        });
      });
      if(_slotObjs.length > 0){
        setSavedSlots(_slotObjs);
        setStep(STEP.PREFERRED);
      }
    }
  },[slotPreferences, interviewCandidate]);

  React.useEffect(() => {
    const getSpecDetails = async (id: string, accountId: string, token: string) => {
      fetch(`${apiUrl}/pub_api/v1/cal/v2/interview_candidate?interview_candidate_id=${id}&account_id=${accountId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Api-Key ${token}`
        },
        credentials: 'include'
      }).then((response) => response.json()).then((data) => {
        setInterviewCandidate(data.candidate as IInterviewCandidate);
      }).catch((error) => {
        console.log('error', error);
      });
    }
    if(id && accountId && token){
      getSpecDetails(id, accountId, token);
    }
  }, []);


  React.useEffect(() => {
    const getSpecDetails = async (id: string) => {
      fetch(`${apiUrl}/pub_api/v1/cal/spec?spec_id=${id}&account_id=${accountId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Api-Key ${token}`
        },
        credentials: 'include'
      }).then((response) => response.json()).then((data) => {
        setSpecDetails(data.spec as IInterviewSpec);
        setIsLoading(false);
      }).catch((error) => {
        console.log('error', error);
      });
    }
    if(specAssignment && specAssignment?.interview_spec_id && accountId && token){
      getSpecDetails(specAssignment?.interview_spec_id);
    }
  }, [accountId, specAssignment]);

  React.useEffect(() => {
    if(interviewSlots){
      setSlotsByDay(slotsByDayHelper(interviewSlots));
    }
  }, [interviewSlots]);
  return <CandidateInterviewWrapper>
    <ContentWrapper>
      {erroredOnLoadingSlots && <HiInfo style={{marginTop: 8, color: theme.colors['red-400']}}>Error loading slots. Please try again later.</HiInfo>}
      {specDetails && interviewCandidate &&  !loading && step === STEP.INTRO && <InterviewInfo interviewCandidate={interviewCandidate} logoUrl={savedLogo} spec={specDetails} goToSchedule={step2} candidateName={candidateName} />}
      {specDetails && !loading && step === STEP.CALENDAR && <>
        {overBlockError &&<HiInfo style={{marginTop: 8, color: theme.colors['red-400']}}>You can only block a maximum of 3 slots.</HiInfo>
        }
        <Schedule 
          slotsByDay={slotsByDay}
          selectedSlot={slotObj}
          selectedSlots={slotObjs}
          updateSlot={updateSlot}
          goToNext={goToConfirmation}
          requestAvailabilityObj={requestAvailabilityObj}
          updatePreferredOpenSlots={updatePreferredOpenSlots}
          specDetails={specDetails}
          preferredOpenSlots={preferredOpenSlots}
        /></>
      }
      {(slotObjs.length > 0 || preferredOpenSlots.length > 0) && specAssignment && id && !loading && accountId && step === STEP.FORM && <ConfirmationForm 
          slotObjs={slotObjs}
          goBack={step2}
          interview_candidate_id={id}
          onSubmitCB={onSubmitCB}
          refetchSlots={refetchSlots}
          logoUrl={savedLogo}
          accountId={accountId}
          specAssignment={specAssignment}
          preferredOpenSlots={preferredOpenSlots}
          isCandidatePrefSlot={requestAvailabilityObj?.open_slots_requested ? true : false}
      />}
      {loading && <Skeleton title={false} loading={loading} paragraph={{rows: 4}}/>}
      {savedSlots && !slotObj && savedSlots.length > 0 && <InfoWrapper>
        <LogoWrapper style={{marginBottom: 24}}><img src={savedLogo || logo} alt="findem-logo"/></LogoWrapper>
        <HI>We have your preferences for following dates!</HI>
        <HiInfo style={{marginTop: 8}}>
          <>
            <TimeSlotWrapper>
              {savedSlots.map((slotObj: ISlot, index: number) => {
                return (
                  <div key={index}>
                    <DayTitle>{moment(slotObj.slot[0].start).format('dddd, MMMM Do, YYYY')}</DayTitle>
                    {slotObj.slot.map((slot: ICandidateInterviewSlot, index: number) => {
                      return (
                        <ItemLabel2 key={index}>
                          {moment(slot.start).format('h:mm A')} - {moment(slot.end).format('h:mm A')}
                          {index < slotObj.slot.length - 1 && <ItemLabel2 style={{marginLeft: '8px', marginRight: 0}}>|</ItemLabel2>}
                        </ItemLabel2>
                      );
                    })}
                  </div>
                );
              })}
            </TimeSlotWrapper>
          </>
        </HiInfo>
      </InfoWrapper>}
      {/* need to unify it with above for time being dups*/}
      {savedCandidateSlots && !slotObj && Object.keys(savedCandidateSlots).length > 0 && <InfoWrapper>
        <LogoWrapper style={{marginBottom: 24}}><img src={savedLogo || logo} alt="findem-logo"/></LogoWrapper>
        <HI>We have your preferences for following dates!</HI>
        <HiInfo style={{marginTop: 8}}>
          <>
            <TimeSlotWrapper>
                {Object.keys(savedCandidateSlots).map((day, index) => {
                return (
                  <div key={index}>
                    <DayTitle>{moment(day).format('dddd, MMMM Do, YYYY')}</DayTitle>
                    {savedCandidateSlots[day].map((slot, index) => {
                      return (
                        <ItemLabel2 key={index}>
                          {moment.unix(slot[0]).format('h:mm A')} - {moment.unix(slot[1]).format('h:mm A')}
                          {index < savedCandidateSlots[day].length - 1 && <ItemLabel2 style={{marginLeft: '8px', marginRight: 0}}>|</ItemLabel2>}
                        </ItemLabel2>
                      );
                    })}
                  </div>
                );
              })}
            </TimeSlotWrapper>
          </>
        </HiInfo>
      </InfoWrapper>}
      {slotObj && <InfoWrapper>
        <LogoWrapper style={{marginBottom: 24}}><img src={savedLogo || logo} alt="findem-logo"/></LogoWrapper>
        <HI>Interview Booked</HI>
        <HiInfo style={{marginTop: 8}}>
        {(!branding || !branding.thank_you_msg) && <>Your interview has been booked for {moment(slotObj.slot[0].start).format('dddd, MMMM Do')} at {moment(slotObj.slot[0].start).format('h:mm A')}.</>}
          {branding && branding.thank_you_msg && <> {branding.thank_you_msg.replace('{{date}}', `${moment(slotObj.slot[0].start).format('dddd, MMMM Do')} at ${moment(slotObj.slot[0].start).format('h:mm A')}`)}</>}
        </HiInfo>
      </InfoWrapper>}
    </ContentWrapper>
  </CandidateInterviewWrapper>;
};

export default CandidateInterview;