import 'maui/dist/styles/maui.css';

import { ThemeProvider } from 'styled-components';
import { theme } from 'maui';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';

// Utils
import { store } from './store'

// Styled + Components
import AppStyles from './App.styled';
import AppRoutes from './components/Routes';

const App = () => {


  //--------------------------------------------------------------------------------
  // Effects
  //--------------------------------------------------------------------------------


  return (
    <ThemeProvider theme={theme}>
      <AppStyles />
      <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
    </ThemeProvider>
  )
}
const LiftedApp = () => {

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default LiftedApp;
