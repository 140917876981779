import { Button, Switch } from "maui";
import styled from "styled-components";

export const PrimaryButton = styled(Button)<{
  $color?: string;
  $textColor?: string;
}>`
  background-color: ${({ theme, $color }) => $color || theme.colors['blue-400']} !important;
  border-color: ${({ theme, $color }) => $color || theme.colors['blue-400']} !important;
  color: ${({ theme, $textColor }) => $textColor || theme.colors['white']} !important;
`;

export const CustomSwitch = styled(Switch)<{
  $color?: string;
}>`
  &.switch-color.ant-switch-checked  {
    background-color: ${({ theme, $color }) => $color || theme.colors['blue-400']} !important;
  }
`;