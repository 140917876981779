import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { theme } from "maui";
import { DefaultTheme } from "styled-components";
import { IInterviewBranding } from "../types/calendar";

export interface IAppState {
  theme: DefaultTheme;
  accountId: string | undefined;
  captchaToken: string | undefined;
  branding: IInterviewBranding | undefined;
}

export const initialState: IAppState = {
  theme: theme,
  accountId: undefined,
  captchaToken: undefined,
  branding: undefined,
}

const appState = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setTheme(state: IAppState, action: PayloadAction<DefaultTheme>) {
      state.theme = action.payload;
    },
    setAccountId(state: IAppState, action: PayloadAction<string>) {
      state.accountId = action.payload;
    },
    setCaptchaToken(state: IAppState, action: PayloadAction<string>) {
      state.captchaToken = action.payload;
    },
    setBranding(state: IAppState, action: PayloadAction<IInterviewBranding>) {
      state.branding = action.payload;
    },
  }
});

export const appStateActions = appState.actions;

export default appState.reducer;