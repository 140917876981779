import moment from "moment";
import { ICandidateInterviewSlot, ISlot } from "../../types/calendar";

export const durationInMinutes =  (slot: ICandidateInterviewSlot[]) => {
  let totalDuration = 0;
  slot.forEach((s: ICandidateInterviewSlot) => {
    totalDuration += s.end - s.start;
  });
  return totalDuration / 60000;
}

export const slotsByDayHelper = (interviewSlots: ICandidateInterviewSlot[][]) => {
  const hasSlots = interviewSlots && interviewSlots.length > 0;
  const slotsByDay: { [day: string]: ISlot[] } = {};
  const _cache: number[] = [];
  if(hasSlots){
    interviewSlots.forEach((slot:ICandidateInterviewSlot[]) => {
      const day = moment(slot[0].start).format('YYYY-MM-DD');
      if (!_cache.includes(slot[0].start) && slot.length > 0) {
        if (!slotsByDay[day]) {
          slotsByDay[day] = [];
        }
        slotsByDay[day].push({
          slot,
          label: moment(slot[0].start).format('h:mm A') + ' - ' + moment(slot.at(-1)?.end).format('h:mm A'),
          duration: durationInMinutes(slot)
        });
      }
    });  
  }
  return hasSlots ? slotsByDay : undefined;
}

export const preferredOpenSlotsGroupByDay = (preferredOpenSlots: [start_ts: number, end_ts: number][]) => {
  const _slots: {[key: string]: [start_ts: number, end_ts: number][]} = {};
  [...preferredOpenSlots].sort((a, b) => a[0] - b[0]).forEach((slot) => {
    const day = moment.unix(slot[0]).format('YYYY-MM-DD');
    console.log('day', day, slot, new Date(slot[0]));
    if(!_slots[day]) {
      _slots[day] = [];
    }
    _slots[day].push(slot);
  });
  return _slots;
};