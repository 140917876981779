import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { constructUrlWithParams, interviewUrl, pubUrl, returnUrlParamAccountIdOrJoid } from 'findem-helpers';

import { IBookCandidateInterviewOneOffParams, IBookCandidateInterviewParams, ICandidateInterviewSlot, IInterviewBranding, IInterviewCandidateOneOffParams, IInterviewCandidateParams1, IInterviewEvents, IInterviewer, IInterviewersInfoResp, IProfile, ISpecAssignment, ITeamParams } from '../types/calendar';

interface IAccountJoidParams {
  accountId: string | undefined;
  joid: string | undefined;
}

interface IDetailsParams extends IAccountJoidParams {
  id: string;
}

interface IICPInterviewSpecParams extends IAccountJoidParams {
  icp_id: string;
}

interface IProfileParams {
  profile_ids: string[];
}

export const interviewsApi = createApi({
  reducerPath: 'interviews',
  baseQuery: fetchBaseQuery({
    baseUrl: interviewUrl
  }),
  tagTypes: ['getInterviewers', 'getInterviewer'],
  endpoints: (build) => ({
    getInterviewers: build.query<IInterviewersInfoResp[], IAccountJoidParams>({
      providesTags: (_r, _e, { accountId, joid }) => [{ type: 'getInterviewers', accountId, joid }],
      query: (params: IAccountJoidParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        const url: string = constructUrlWithParams('/interview/api/interviewer/account', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET'
        }
      },
      transformResponse: (response: {interviewer_info: IInterviewersInfoResp[]}) => response.interviewer_info
    }),
    getInterviewer: build.query<IInterviewer, IDetailsParams>({
      providesTags: (_r, _e, { accountId, joid, id }) => [{ type: 'getInterviewer', id, accountId, joid }],
      query: (params: IDetailsParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        queryParams.id = params.id;
        const url: string = constructUrlWithParams('/interview/api/interviewer', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET'
        }
      },
      transformResponse: (response: {interviewer: IInterviewer}) => response.interviewer
    }),
    createOrUpdateInterviewer: build.mutation<IInterviewer, ITeamParams>({
      query: (team: ITeamParams) => ({
        url: `/interview/api/interviewer`,
        credentials: 'include',
        method: 'PUT',
        body: {...team, preferences: { max_interview_per_week: 30 }}
      }),
      transformResponse: (response: IInterviewer) => response
    }),
    getIcpInterviewSpecsC: build.query<ISpecAssignment, IICPInterviewSpecParams>({
      query: (params: IICPInterviewSpecParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        queryParams.icp_id = params.icp_id;

        const url: string = constructUrlWithParams('/interview/api/spec/search', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET'
        }
      },
      transformResponse: (response: {spec_assignment: ISpecAssignment}) => response.spec_assignment
    }),
    getCandidateInterviewC: build.query<ICandidateInterviewSlot[][], IInterviewCandidateParams1>({
      query: (params: IInterviewCandidateParams1) => {
        const queryParams = {
          interview_candidate_id: params.interview_candidate_id,
          ...returnUrlParamAccountIdOrJoid(params.accountId, undefined)
        };
        const url: string = constructUrlWithParams('/interview/api/interview_candidate/available_slots', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET'
        }
      },
      transformResponse: (response: ICandidateInterviewSlot[][]) => response
    }),
    getCandidateInterviewOneOffC: build.query<ICandidateInterviewSlot[][], IInterviewCandidateOneOffParams>({
      query: (params: IInterviewCandidateOneOffParams) => {
        const queryParams = {
          slug: params.slug,
        };
        const url: string = constructUrlWithParams('/interview/api/interview_candidate/oneoff/available_slots', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET'
        }
      },
      transformResponse: (response: ICandidateInterviewSlot[][]) => response
    }),
    bookCandidateInterviewC: build.mutation<IInterviewEvents, IBookCandidateInterviewParams>({
      query: (params: IBookCandidateInterviewParams) => ({
        url: `/interview/api/interview_candidate/book_slot`,
        credentials: 'include',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: {events: IInterviewEvents}) => response.events
    }),
    bookCandidateInterviewOneOffC: build.mutation<IInterviewEvents, IBookCandidateInterviewOneOffParams>({
      query: (params: IBookCandidateInterviewOneOffParams) => ({
        url: `/interview/api/interview_candidate/book_oneoff_slot`,
        credentials: 'include',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: {events: IInterviewEvents}) => response.events
    }),
    getProfiles: build.query<Record<string, IProfile>, IProfileParams>({
      query: (body) => {
        // BE Doesn't reject this ATM but if you're requesting for more than 1K
        // profiles, either paginate or seek alternative solution for request size.
        if (body.profile_ids.length > 1000) {
          console.error('API Error: Too Many PRIDs');
        }
        return {
          url: `${pubUrl}/pub/api/profile`,
          credentials: 'include',
          method: 'POST',
          body: {
            detail: 'false',
            prids: body.profile_ids,
            bust_cache: false,
            type: 'Profiles'
          }
        }
      },

      transformResponse: (response: Record<string, IProfile>, meta, arg) =>
        response
    }),
    getInterviewPageBranding: build.query<IInterviewBranding, IAccountJoidParams>({
      query: (params: IAccountJoidParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        const url: string = constructUrlWithParams('/interview/api/interview_branding', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET'
        }
      },
      transformResponse: (response: {branding: IInterviewBranding}) => response.branding
    }),
    getInterviewPageLogo: build.query<string, IAccountJoidParams>({
      query: (params: IAccountJoidParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        const url: string = constructUrlWithParams('/interview/api/interview_branding/logo', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET'
        }
      },
      transformResponse: (response: {logo: string}) => response.logo
    }),
  })
});

export const {
  endpoints,
  useGetInterviewersQuery,
  useGetInterviewerQuery,
  useCreateOrUpdateInterviewerMutation,
  useGetIcpInterviewSpecsCQuery,
  useGetCandidateInterviewCQuery,
  useGetCandidateInterviewOneOffCQuery,
  useBookCandidateInterviewCMutation,
  useBookCandidateInterviewOneOffCMutation,
  useGetProfilesQuery,
  useGetInterviewPageBrandingQuery,
  useGetInterviewPageLogoQuery,
} = interviewsApi;
