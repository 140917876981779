import { DOMAIN_PRIMARY, DOMAIN_TALENT_COM, getEnvironmentDomain } from "findem-helpers";

type captchaKeys = Record<string, string>;

const recatpchaKey: Record<string, captchaKeys> = {
  [DOMAIN_TALENT_COM]: {
    staging: '6LfPDwsoAAAAALfTYJOoPwfFgKc-CfyDwh5VYIu8',
    testing: '6LfPDwsoAAAAALfTYJOoPwfFgKc-CfyDwh5VYIu8',
    preprod: '6Ldn28MmAAAAAC8kdI3cf7JL19f8kFpXXoMoTbiE',
    prod: '6LdZDBcoAAAAAN4w3Q_6njaNn_TwYZmG3Aixz2NM',
  },
}

export const getRecaptchaKey = (): string => {
  const env: string = process.env.REACT_APP_DEPLOY_ENV || process.env.DEPLOY_ENV || 'prod';
  return recatpchaKey[DOMAIN_TALENT_COM][env] ?? 'undefined';
};