import { Icon, InputNumber } from "maui";
import styled from "styled-components";

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors['white']};
`;

export const Container = styled.div`
  width: 600px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 40px;
  margin: 0 auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const H1 = styled.div`
  width: 100%;
  line-height: 57.6px;
  font-size: ${({ theme }) => theme.dimensions.spacing[4]};
  font-weight: 500;
  text-align: center;
`;
export const H1Info = styled.div`
  font-size: ${({ theme }) => theme.dimensions.spacing[2]};
  color: ${({ theme }) => theme.colors['grey-500']};
`;

export const TimeZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.dimensions.spacing[2]};
  color: ${({ theme }) => theme.colors['grey-700']};
  font-weight: 500;
`;
export const DayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  align-items: start;
`;
export const DayTitle = styled.div`
  color: ${({ theme }) => theme.colors['grey-600']};
  width: 150px;
`;
export const TimeRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;
export const ActionWrapper = styled.div`
  width: 100%;
`;
export const IconWrapper = styled.div`
  display: inline-block;
`;
export const StyledIcon = styled(Icon)<{
  $hoverColor?: string;
}>`
  font-size: 18px;
  margin-right: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors['grey-500']};
  ${({ $hoverColor }) => $hoverColor && `
    &:hover {
      color: ${$hoverColor};
    }
  `}
`;
export const SuccessIcon = styled(Icon)`
  font-size: 64px;
  color: ${({ theme }) => theme.colors['green-400']};
  background-color: ${({ theme }) => theme.colors['green-100']};
  border-radius: 50%;
  padding: 12px;
`;
export const DivInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
export const StyledInputNumber = styled(InputNumber)`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: ${({ theme: { dimensions } }) => dimensions.spacing[1]};

  .ant-select-selector {
    display: flex;
    flex: 1 1 auto;
  }
`;
export const GreySpan = styled.span`
  color: ${({ theme: { colors } }) => colors['grey-500']};
  font-size: 13px;
  font-weight: 400;
`;