import React, { Suspense, useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Loader, theme } from 'maui';
import NotFound from '../Error/404';
import InterviewerConfig from '../InterviewerConfig';
import CandidateInterview from '../CandidateInterview';
import PersonalBooking from '../PersonalBooking';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { useUrlParams } from 'findem-helpers';
import hexToRgba from 'hex-to-rgba';
import { useDispatch } from 'react-redux';
import { appStateActions } from '../../reducers/appState';
import { useSelector } from 'react-redux';
import { useGetPubInterviewPageBrandingByCaptchaQuery, useGetPubInterviewPageBrandingQuery } from '../../services/pub-interviews';

// Components


const AppRoutes = () => {
  const {accountId, token} = useUrlParams();
  const dispatch = useDispatch();

  const _accountId: string | undefined = useSelector((state: any) => state.appState.accountId);
  const _captchaToken: string | undefined = useSelector((state: any) => state.appState.captchaToken);
  
  const { data: brandingByToken } = useGetPubInterviewPageBrandingQuery({accountId: _accountId, joid: undefined, token: token || ''}, {skip: !_accountId || !token || token.length < 1});
  const { data: brandingByCaptcha } = useGetPubInterviewPageBrandingByCaptchaQuery({accountId: _accountId, joid: undefined, token: _captchaToken || ''}, {skip: !_accountId || !_captchaToken});

  const branding = React.useMemo(() => {
    if(brandingByToken) return brandingByToken;
    if(brandingByCaptcha) return brandingByCaptcha;
    return undefined;
  }, [brandingByToken, brandingByCaptcha]);

  const themeC: DefaultTheme = React.useMemo(() => {
    if(!branding || !branding.theme_color) return theme;
    return {
      ...theme,
      colors: {
        ...theme.colors,
        'grey-100': hexToRgba(branding.theme_color , 0.1),
        'grey-200': hexToRgba(branding.theme_color , 0.2),
        'blue-400': hexToRgba(branding.theme_color , 1),
        'blue-100': hexToRgba(branding.theme_color , 0.1),
        'blue-200': hexToRgba(branding.theme_color , 0.2),
        'blue-300': hexToRgba(branding.theme_color , 0.6),
      }
    }
  }, [branding]);

  useEffect(() => {
    if(!themeC) return;
    dispatch(appStateActions.setTheme(themeC));
  }, [dispatch, themeC]);

  useEffect(() => {
    if(!accountId) return;
    dispatch(appStateActions.setAccountId(accountId));
  }, [dispatch, accountId]);

  useEffect(() => {
    if(branding){
      dispatch(appStateActions.setBranding(branding));
    }
  }, [dispatch, branding]);

  return (
    <ThemeProvider theme={themeC}>
      <Suspense fallback={<Loader $fullPage />}>
        <Routes>
          <Route path='/' element={<NotFound/>} />
          <Route path='/:id' element={<PersonalBooking/>} />
          <Route path='/configure/:id' element={<InterviewerConfig/>} />
          <Route path='/book/:id' element={<CandidateInterview/>} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  )
}

export default AppRoutes;