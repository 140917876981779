import styled, { createGlobalStyle } from 'styled-components';
import { theme } from 'maui';

// @TODO Move this to the sidebar component
export const SIDEBAR_WIDTH: {
  collapsed: number;
  expanded: number;
} = {
  collapsed: 70,
  expanded: 290
};


const AppStyles = createGlobalStyle`
  html {
    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    background-color: ${theme.colors['white']};
  }

  #root {
    height: 100%;
  }

  button {
    cursor: pointer;
  }

  blockquote {
    color: ${theme.colors['grey-500']};
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
`;

export default AppStyles;