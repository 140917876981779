import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { interviewsApi } from './services/interviews';
import { interviewsPubApi } from './services/pub-interviews';
import * as customReducers from './reducers';

const rootReducer = combineReducers({
  [interviewsApi.reducerPath]: interviewsApi.reducer,
  [interviewsPubApi.reducerPath]: interviewsPubApi.reducer,
  ...customReducers
});

// Add any RTK Query reducers here and we can sanitize them
// In order to have redux devtools run efficiently. It doesn't have
// To be all of them, any with large datasets such as search + campaigns.
const apiReducers: string[] = [
  interviewsApi.reducerPath,
  interviewsPubApi.reducerPath
];

export const setupStore = (preloadedState?: RootState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(
        interviewsApi.middleware,
        interviewsPubApi.middleware
      ),
    preloadedState,
    devTools: {
      actionSanitizer: (action: any) => {
        if (
          apiReducers.some((apiReducer: string) =>
            action.type.startsWith(`${apiReducer}/`)
          )
        ) {
          return {
            ...action,
            meta: '<<LONG_BLOB>>',
            payload: '<<LONG_BLOB>>'
          };
        }

        return action;
      },
      stateSanitizer: (state: any) => ({
        ...state,
        ...apiReducers.reduce(
          (sanitizedState: { [key: string]: string }, apiReducer: string) => {
            if (state[apiReducer]) {
              sanitizedState[apiReducer] = '<<LONG_BLOB>>';
            }

            return sanitizedState;
          },
          {}
        )
      })
    }
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const store = setupStore();
