import React from 'react';
// types
import { ICandidateRequestAvailability, IInterviewCandidateSlots, IInterviewSpec, ISlot } from '../../types/calendar';
// components
import ScheduleBySystemPref from './ScheduleBySystemPref';
import ScheduleByCandidatePref from './ScheduleByCandidatePref';

interface IScheduleProps {
  slotsByDay: IInterviewCandidateSlots | undefined;
  selectedSlot: ISlot | undefined;
  updateSlot: (slot: ISlot) => void;
  selectedSlots: ISlot[];
  goToNext: () => void;
  requestAvailabilityObj: ICandidateRequestAvailability | undefined;
  updatePreferredOpenSlots?: (slot: [start_ts: number, end_ts: number], operation: 'add' | 'remove') => void;
  specDetails?: IInterviewSpec;
  preferredOpenSlots?: [start_ts: number, end_ts: number][];
}

const Schedule: React.FC<IScheduleProps> = (props: IScheduleProps) => {
  const { requestAvailabilityObj } = props;

  const isReqForCandidatePref = React.useMemo(() => {
    return requestAvailabilityObj?.open_slots_requested ? true : false;
  }, [requestAvailabilityObj]);

  return (
    <>
      {isReqForCandidatePref && <ScheduleByCandidatePref {...props } />}
      {!isReqForCandidatePref && <ScheduleBySystemPref {...props } />}
    </>
  );
};

export default Schedule;