import React from 'react';
import { BackWrapper, DayTitle, ItemLabel2, ItemLabel, LogoWrapper, ScheduleItemWrapper, ScheduleWrapper, TimeSlotWrapper } from './CandidateInterview.styled';
import moment from 'moment';
import logo from '../../assets/svgs/findem_logo.svg';
import { Icon } from 'maui';
import { Button } from 'antd';
import { ICandidateInterviewSlot, ICandidateSlotsPrefsParams, IPreferredSlot, ISlot, ISpecAssignment } from '../../types/calendar';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { useUrlParams } from 'findem-helpers';
import { usePostCandidatePubInterviewSlotsMutation } from '../../services/pub-interviews';
import { preferredOpenSlotsGroupByDay } from './helper';

interface IConfirmationFormProps {
  slotObjs: ISlot[];
  goBack: () => void;
  interview_candidate_id: string;
  onSubmitCB: () => void;
  refetchSlots: () => void;
  isPersonalCal?: boolean;
  logoUrl?: string | undefined;
  recaptcha?: any;
  accountId: string;
  specAssignment?: ISpecAssignment;
  preferredOpenSlots?: [start_ts: number, end_ts: number][];
  isCandidatePrefSlot?: boolean;
}

const ConfirmationForm: React.FC<IConfirmationFormProps> = ({ 
  slotObjs, goBack, interview_candidate_id, 
  onSubmitCB, refetchSlots, isPersonalCal, 
  logoUrl, recaptcha, accountId,
  specAssignment, preferredOpenSlots, isCandidatePrefSlot
}) => {
  const {token} = useUrlParams();
  
  const [bookCandidatePrefs, {isLoading: isSubmitting}] = usePostCandidatePubInterviewSlotsMutation();

  const themeC: DefaultTheme = useSelector((state: any) => state.appState.theme);

  const [showError, setShowError] = React.useState<boolean>(false);
  const [tokenParam, setTokenParam] = React.useState<string | undefined>(undefined);

  const preferredSlotsGroupByDay = React.useMemo(() => {
    if(!preferredOpenSlots) return {};
    return preferredOpenSlotsGroupByDay(preferredOpenSlots);
  }, [preferredOpenSlots]);

  const onSubmit = React.useCallback( async () => {
    if(!specAssignment) return;
    setShowError(false);
    let _onDemandToken: string | undefined = undefined;
    if(recaptcha) {
      _onDemandToken = await recaptcha?.current?.execute();
    }
    const payload: ICandidateSlotsPrefsParams = {
      candidate_ref: interview_candidate_id,
      booking_slot_details: [],
      spec_ref: specAssignment.interview_spec_id,
      token: _onDemandToken || tokenParam || '',
      preferred_open_slots: preferredOpenSlots || [],
    }
    !isCandidatePrefSlot && slotObjs.forEach((slotObj : ISlot) => {
      const _slot: IPreferredSlot = {};
      slotObj.slot.forEach((slot: ICandidateInterviewSlot)=>{
        _slot[slot.interviewer_id] = {
          start_ts: slot.start,
          end_ts: slot.end,
        };
      });
      payload.booking_slot_details.push(_slot);
    });
    const resp = await bookCandidatePrefs(payload);
    if('error' in resp) {
      console.log('error', resp.error);
      if('data' in resp.error && resp.error.data === 'Retry with new slots.') {
        refetchSlots();
        setShowError(true);
      }
      return;
    }
    if('data' in resp) {
      onSubmitCB();
    }
  }, [specAssignment, recaptcha, interview_candidate_id, 
    tokenParam, preferredOpenSlots, isCandidatePrefSlot, 
    slotObjs, bookCandidatePrefs, refetchSlots, onSubmitCB
  ]);

  const goBackHandler = React.useCallback(() => {
    setShowError(false);
    goBack();
  }, [goBack]);

  React.useEffect(() => {
    if(token){
      setTokenParam(token);
    }
  }, [token]);
  return (<>
    <ScheduleWrapper>
      <ScheduleItemWrapper showBackground={true}>
        <BackWrapper onClick={goBackHandler}><Icon icon="arrow_back" $size={18}/>Back to Availability</BackWrapper> 
        <DayTitle>{isCandidatePrefSlot ? 'Your' : 'Selected'} Preferred Slots</DayTitle>
        <TimeSlotWrapper>
          {!isCandidatePrefSlot && slotObjs.map((slotObj: ISlot, index: number) => {
            return (
              <div key={index}>
                <DayTitle>{moment(slotObj.slot[0].start).format('dddd, MMMM Do, YYYY')}</DayTitle>
                {slotObj.slot.map((slot: ICandidateInterviewSlot, index: number) => {
                  return (
                    <ItemLabel2 key={index}>
                      {moment(slot.start).format('h:mm A')} - {moment(slot.end).format('h:mm A')}
                      {index < slotObj.slot.length - 1 && <ItemLabel2 style={{marginLeft: '8px', marginRight: 0}}>|</ItemLabel2>}
                    </ItemLabel2>
                  );
                })}
              </div>
            );
          })}
          {isCandidatePrefSlot && preferredSlotsGroupByDay && Object.keys(preferredSlotsGroupByDay).map((day, index) => {
            return (
              <div key={index}>
                <DayTitle>{moment(day).format('dddd, MMMM Do, YYYY')}</DayTitle>
                {preferredSlotsGroupByDay[day].map((slot, index) => {
                  return (
                    <ItemLabel2 key={index}>
                      {moment.unix(slot[0]).format('h:mm A')} - {moment.unix(slot[1]).format('h:mm A')}
                      {index < preferredSlotsGroupByDay[day].length - 1 && <ItemLabel2 style={{marginLeft: '8px', marginRight: 0}}>|</ItemLabel2>}
                    </ItemLabel2>
                  );
                })}
              </div>
            );
          })}
        </TimeSlotWrapper>
      </ScheduleItemWrapper>
      <ScheduleItemWrapper style={{marginTop: 0}}>
        <LogoWrapper><img src={logoUrl || logo} alt="findem-logo"/></LogoWrapper>
        <TimeSlotWrapper>
          {showError && <ItemLabel style={{color: 'red'}}>Please select new slots.</ItemLabel>}
        </TimeSlotWrapper>
        <Button 
          loading={isSubmitting} 
          onClick={onSubmit} disabled={slotObjs.length === 0 && preferredOpenSlots?.length === 0} 
          style={{
            marginTop: 32, 
            width: '100%',
            color: (slotObjs.length === 0) ? themeC.colors['grey-500'] : themeC.colors['white'],
            backgroundColor: (slotObjs.length === 0) ? themeC.colors['white'] : themeC.colors['blue-400'],
            borderColor: (slotObjs.length === 0) ? themeC.colors['grey-200'] : themeC.colors['blue-400'],
          }}
        >Submit</Button>
      </ScheduleItemWrapper>
    </ScheduleWrapper>
  </>);
}

export default ConfirmationForm;